import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  updateOrderSettingModalIsVisibleForLocationsPage,
  updateInterOrderType,
  changePickUpLocationFromLocationsPage,
  updateOrderType,
  updateOrderSettingModalIsVisible,
  resetInterSelectedDateTime,
} from '../OrderSetting/orderSettingActions';
import { DELIVERY, PICKUP } from '../OrderSetting/orderSettingTypes';
import LocationSelector from './components/LocationSelector';
import { navigate } from 'gatsby';
import { scroller } from 'react-scroll';
import { getStates } from '../../utils/parseProvinceAbbr';
import appConfig from '../../../app-config';
import { DROPOFF } from '../OrderSetting/OrderSettingModal/utils/helper';

const LocationSelectorContainer = ({
  locations,
  business_id,
  selectedPickUpLocation,
  changePickUpLocationFromLocationsPage,
  updateOrderSettingModalIsVisibleForLocationsPage,
  updateInterOrderType,
  specialHours,
  childLocations,
  interPickUpDetails,
  updateOrderType,
  interOrderType,
  updateOrderSettingModalIsVisible,
  orderSettingModalIsVisible,
  resetInterSelectedDateTime,
}) => {
  const [locationToShowOnMobile, setLocationToShowOnMobile] = useState(null);
  const [
    idOfExpandedLocationPreview,
    setIdOfExpandedLocationPreview,
  ] = useState(null);

  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const sortAlphabetically = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    setFilteredLocations(locations);
    if (locations) {
      const locationProvinces = locations.map((location) => location.state);
      const filteredProvinces = getStates().filter(
        (province) => locationProvinces.indexOf(province.abbreviation) > -1
      );
      filteredProvinces.sort((a, b) => sortAlphabetically(a, b));
      filteredProvinces.unshift({ name: 'All', abbreviation: 'ALL' });
      const filteredCities = Array.from(
        new Set(locations.map((location) => location.city))
      ).map((city) => ({ name: city, value: city }));
      filteredCities.sort((a, b) => sortAlphabetically(a, b));
      filteredCities.unshift({ name: 'All', value: 'ALL' });
      setFilteredCities(filteredCities);
      setFilteredProvinces(filteredProvinces);
    }
  }, [locations]);

  useEffect(() => {
    if (locations && locations.length && selectedProvince) {
      setSelectedCity(null);
      let filteredCities = Array.from(
        new Set(
          locations
            .filter(
              (location) => location.state === selectedProvince.abbreviation
            )
            .map((location) => location.city)
        )
      ).map((city) => ({ name: city, value: city }));
      filteredCities.sort((a, b) => sortAlphabetically(a, b));
      filteredCities.unshift({ name: 'All', value: 'ALL' });
      setFilteredCities(filteredCities);
    }
  }, [selectedProvince]);
  useEffect(() => {
    if (locations && locations.length) {
      applySearch();
    }
  }, [searchQuery, selectedProvince, selectedCity]);

  const handleLocationSearch = (event) =>
    setSearchQuery(event.target.value.toLowerCase());

  const handleProvinceSearch = (selectedProvince) => {
    if (selectedProvince.abbreviation === 'ALL')
      setFilteredLocations(locations);
    setSelectedProvince(selectedProvince);
  };

  const handleCitySearch = (selectedCity) => {
    if (selectedCity.value === 'ALL') setFilteredLocations(locations);
    setSelectedCity(selectedCity);
  };

  const applySearch = () => {
    const filteredLocations = locations.filter((location) => {
      const name = location.name.toLowerCase();
      const line1 = location.line1.toLowerCase();
      const city = location.city;
      const state = location.state;
      return (
        (name.indexOf(searchQuery) !== -1 ||
          line1.indexOf(searchQuery) !== -1) &&
        (selectedProvince && selectedProvince.abbreviation !== 'ALL'
          ? state === selectedProvince.abbreviation
          : true) &&
        (selectedCity && selectedCity.value !== 'ALL'
          ? city === selectedCity.value
          : true)
      );
    });
    setFilteredLocations(filteredLocations);
  };

  const orderFromHere = (location) => {
    const deepLink = localStorage.getItem('deep-link-url');
    if (deepLink) {
      navigate('/' + deepLink);
    } else {
      navigate('/');
    }
    updateOrderSettingModalIsVisible(true);
    if (
      location.accepted_order_types.length === 1 &&
      location.accepted_order_types[0] === DELIVERY
    ) {
      updateInterOrderType(DELIVERY);
    } else {
      updateInterOrderType(PICKUP);
      changePickUpLocationFromLocationsPage(location);
      resetInterSelectedDateTime();
    }
  };

  const handleSetIdOfExpandedLocationPreview = (
    idOfExpandedLocationPreviewToSet
  ) => {
    if (idOfExpandedLocationPreviewToSet == idOfExpandedLocationPreview) {
      setIdOfExpandedLocationPreview(null);
    } else {
      setIdOfExpandedLocationPreview(idOfExpandedLocationPreviewToSet);
    }
  };

  const scrollToLocation = (locationId) => {
    const offset = window && window.innerWidth <= 786 ? -280 : -100;
    scroller.scrollTo(locationId, {
      containerId: 'locationsList',
      duration: 800,
      delay: 0,
      offset,
      smooth: 'easeInOutQuart',
    });
    if (window && window.innerWidth > 786) {
      setIdOfExpandedLocationPreview(locationId);
    }
  };

  return (
    <div>
      {filteredLocations && (
        <LocationSelector
          id="LocationSelector"
          selectedCity={selectedCity}
          handleCitySearch={handleCitySearch}
          filteredCities={filteredCities}
          filteredProvinces={filteredProvinces}
          handleProvinceSearch={handleProvinceSearch}
          locations={filteredLocations}
          selectedPickUpLocation={selectedPickUpLocation}
          handleLocationSearch={handleLocationSearch}
          locationSearchQuery={searchQuery}
          setLocationToShowOnMobile={setLocationToShowOnMobile}
          locationToShowOnMobile={locationToShowOnMobile}
          orderFromHere={orderFromHere}
          scrollToLocation={scrollToLocation}
          idOfExpandedLocationPreview={idOfExpandedLocationPreview}
          setIdOfExpandedLocationPreview={handleSetIdOfExpandedLocationPreview}
          selectedProvince={selectedProvince}
          interOrderType={interOrderType}
          updateInterOrderType={updateInterOrderType}
          specialHours={specialHours}
          childLocations={childLocations}
          interPickUpDetails={interPickUpDetails}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          orderSettingModalIsVisible={orderSettingModalIsVisible}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locations: state.locationsReducer.locations,
  selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
  specialHours: state.orderSettingReducer.specialHours,
  childLocations: state.orderSettingReducer.childLocations,
  business_id: state.cartReducer.cart.business_id,
  interPickUpDetails: state.orderSettingReducer.interPickUpDetails,
  interOrderType: state.orderSettingReducer.interOrderType,
  orderSettingModalIsVisible:
    state.orderSettingReducer.orderSettingModalIsVisible,
});

export default connect(mapStateToProps, {
  changePickUpLocationFromLocationsPage,
  updateOrderSettingModalIsVisibleForLocationsPage,
  updateInterOrderType,
  updateOrderType,
  updateOrderSettingModalIsVisible,
  resetInterSelectedDateTime,
})(LocationSelectorContainer);
