import React, { memo, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Headline, Title2 } from '../../../components/Typography';
import TextField from '@material-ui/core/TextField';
import LocationPreview from './LocationPreview';
import LocationDetails from './LocationDetails';
import LocationsMap from './LocationsMap';
import Select from '../../../components/Select';
import appConfig from '../../../../app-config';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: calc(100vh - 80px);
  @media (max-width: 786px) {
    grid-template-rows: 30vh 1fr;
    grid-template-columns: 1fr;
    // height: calc(100vh - 56px);
    height: 100vh;
  }
`;

const LocationsList = styled.div`
  display: grid;
  font-weight: 600;
  grid-gap: 24px;
  // cursor: pointer;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  align-content: flex-start;
  padding: 20px;
  background: white;
  @media (max-width: 786px) {
    border-radius: 16px;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.15);
    margin-top: 0;
    z-index: 999;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  @media (max-width: 786px) {
    order: -1;
  }
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 13px;
`;

const AllStoresClosed = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme.lightGray};
  padding: 12px;
  margin-top: 12px;
  font-weight: 500;
`;
const MaskIcon = styled.i`
  font-size: 40px;
  font-weight: 300;
  color: #737a87;
`;
const NoSearchedLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NoResultText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #737a87;
  margin: 16px 0 0 0;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;
const DescriptionText = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #737a87;
  margin: 8px 0 0 0;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
`;

const LocationSelector = ({
  locations,
  selectedPickUpLocation,
  orderFromHere,
  handleLocationSearch,
  locationSearchQuery,
  locationToShowOnMobile,
  setLocationToShowOnMobile,
  scrollToLocation,
  idOfExpandedLocationPreview,
  setIdOfExpandedLocationPreview,
  handleProvinceSearch,
  filteredProvinces,
  filteredCities,
  handleCitySearch,
  selectedCity,
  selectedProvince,
  updateInterOrderType,
  specialHours,
  interPickUpDetails,
  interOrderType,
  updateOrderSettingModalIsVisible,
  orderSettingModalIsVisible,
}) => {
  /**
   *
   * @param {array} locations array of all locations
   * @return {array} filtered location list with selected parent location first
   */
  const filterLocations = (locations) => {
    if (interPickUpDetails && locations) {
      let isLocationMatched = false;
      let filteredLocations = locations.filter((location) => {
        if (location.id !== interPickUpDetails.id) {
          return true;
        } else {
          isLocationMatched = true;
          return false;
        }
      });
      if (isLocationMatched) {
        filteredLocations.unshift(interPickUpDetails);
      }

      filteredLocations =
        filteredLocations.length > 0
          ? filteredLocations.filter(
              (location) => location.accepted_order_types.length >= 1
            )
          : [];

      return filteredLocations;
    } else return locations;
  };
  return (
    <Wrapper>
      {locationToShowOnMobile ? (
        <LocationDetails
          location={locationToShowOnMobile}
          setLocationToShowOnMobile={setLocationToShowOnMobile}
          orderFromHere={orderFromHere}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          updateInterOrderType={updateInterOrderType}
        />
      ) : (
        <LocationsList id="locationsList">
          <Title2 id="LocationSelector-Title">LOCATIONS</Title2>

          <FilterWrapper>
            <Select
              id="LocationSelector-SelectProvince"
              placeholder="Filter by Province"
              options={filteredProvinces}
              getOptionLabel={(province) => province.name}
              getOptionValue={(province) => province}
              onChange={(selectedProvince) => {
                handleProvinceSearch(selectedProvince);
                setIdOfExpandedLocationPreview(null);
              }}
              value={selectedProvince}
              fromLocationPage={true}
            />
            <Select
              id="LocationSelector-SelectCity"
              value={selectedCity}
              placeholder="Filter by City"
              options={filteredCities}
              getOptionLabel={(city) =>
                city.name[0].toUpperCase() +
                city.name.substring(1).toLowerCase()
              }
              getOptionValue={(city) => ({ ...city })}
              onChange={(city) => handleCitySearch(city)}
              fromLocationPage={true}
            />
          </FilterWrapper>

          <TextField
            id="LocationSelector-Location"
            value={locationSearchQuery}
            onChange={handleLocationSearch}
            placeholder="Search"
            variant="outlined"
          />
          {locations.length > 0 ? (
            filterLocations(locations).map((location) => {
              return (
                <LocationPreview
                  id="LocationSelector-LocationPreview"
                  key={location.id}
                  location={location}
                  setLocationToShowOnMobile={setLocationToShowOnMobile}
                  orderFromHere={orderFromHere}
                  idOfExpandedLocationPreview={idOfExpandedLocationPreview}
                  setIdOfExpandedLocationPreview={
                    setIdOfExpandedLocationPreview
                  }
                  updateOrderSettingModalIsVisible={
                    updateOrderSettingModalIsVisible
                  }
                  updateInterOrderType={updateInterOrderType}
                  specialHours={specialHours}
                  interOrderType={interOrderType}
                />
              );
            })
          ) : locationSearchQuery.length > 0 ? (
            <NoSearchedLocationWrapper>
              {/* <i className="fas fa-exclamation-circle" /> */}
              <MaskIcon className="fal fa-map-marker-alt"></MaskIcon>
              <NoResultText>No Results</NoResultText>
              <DescriptionText>
                Sorry, we couldn’t find a location by this name
              </DescriptionText>
            </NoSearchedLocationWrapper>
          ) : (
            <AllStoresClosed>
              <i className="fas fa-exclamation-circle" />
              {` ${appConfig.appTitle} is currently not accepting any orders`}
            </AllStoresClosed>
          )}
        </LocationsList>
      )}
      <MapWrapper>
        <LocationsMap
          id="LocationSelector-LocationsMap"
          locations={locations}
          selectedPickUpLocation={selectedPickUpLocation}
          scrollToLocation={scrollToLocation}
          locationPreview={locations.filter(
            (location) => location.id === idOfExpandedLocationPreview
          )}
          selectedProvince={
            // selectedProvince && selectedProvince.abbreviation === 'ALL'
            //   ? {name: 'Ontario', abbreviation: 'ON'}
            //:
            selectedProvince
          }
        />
      </MapWrapper>
    </Wrapper>
  );
};

export default memo(LocationSelector);
