import React, {useState, useEffect, memo} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {geolocated} from 'react-geolocated';
import {setUserCoords} from '../../ProfileSettings/profileSettingsActions';
import myLocationPin from '../../../assets/myLocationPin.png';

const TakeMeToMyLocationWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  right: 10px;
  bottom: 105px;
  border-bottom: 1px solid lightgray;
  padding-top: 2px;
`;

const TakeMeToMyLocationIcon = styled.i`
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 30px;
  margin-top: 7px;
  margin-left: 5px;
  padding: 0 5px;

  @media (max-width: 786px) {
    margin-top: 20px;
  }
`;

const positionObject = location => ({
  lat: location.latitude,
  lng: location.longitude,
});

const LocationsMap = ({
  google,
  locations,
  selectedPickUpLocation,
  locationPreview,
  scrollToLocation,
  coords,
  setUserCoords,
  selectedProvince,
}) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [mapConfigs, setMapConfigs] = useState({
    center: {lat: '', lng: ''},
    zoom: 4,
  });

  useEffect(() => {
    if (coords && mapInstance) {
      setUserCoords({latitude: coords.latitude, longitude: coords.longitude});
      // mapInstance.setZoom(14);
    }
  }, [coords, mapInstance]);

  useEffect(() => {
    const center = selectedPickUpLocation
      ? positionObject(selectedPickUpLocation)
      : locations.length
      ? positionObject(locations[0])
      : '';

    if (locations && locations.length > 1)
      setMapConfigs({...mapConfigs, center});
    else setMapConfigs({center: center, zoom: 14});
  }, [selectedPickUpLocation]);

  useEffect(() => {
    if (coords && mapInstance) {
      bringMeToMyLocation();
    }
  }, [coords, mapInstance]);

  useEffect(() => {
    if (!locationPreview.length) {
      if (typeof window !== 'undefined' && window.innerWidth < 786) {
        setMapConfigs({
          center: {lat: '58.642386', lng: '-99.6314527'},
          zoom: 3,
        });
      } else
        setMapConfigs({
          center: {lat: '58.642386', lng: '-99.6314527'},
          zoom: 4,
        });
    } else
      setMapConfigs({center: positionObject(locationPreview[0]), zoom: 15});
  }, [locationPreview]);

  useEffect(() => {
    const center = locations.length ? positionObject(locations[0]) : '';
    if (selectedProvince) {
      if (selectedProvince.abbreviation === 'ALL') {
        setMapConfigs({
          center: {lat: '56.1304', lng: '106.3468'},
          zoom: 3,
        });
      }
      setMapConfigs({center: center, zoom: 3});
    }
  }, [selectedProvince]);

  const handleMapReady = (mapProps, map) => {
    setMapInstance(map);
    const {google} = mapProps;

    //map search
    let input = document.getElementById('pac-input');
    let searchBox = new google.maps.places.SearchBox(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    let oldSearchMarker = null;
    searchBox.addListener('places_changed', () => {
      let places = searchBox.getPlaces();

      // if (oldSearchMarker) oldSearchMarker.setMap(null);

      // var position = {
      //   lat: places[0].geometry.location.lat(),
      //   lng: places[0].geometry.location.lng(),
      // };

      // const marker = new google.maps.Marker({
      //   position: position,
      // });

      // marker.setMap(map);
      // oldSearchMarker = marker;

      if (places.length == 0) {
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      places.forEach(function(place) {
        if (!place.geometry) {
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      document.getElementById('pac-input').value = '';
      map.fitBounds(bounds);
    });
    //end of map search

    let bounds = new google.maps.LatLngBounds();
    locations.forEach(location => {
      bounds.extend(positionObject(location));
    });
    // if (!coords) {
    //   map.fitBounds(bounds);
    // }
  };

  const bringMeToMyLocation = () => {
    mapInstance.setZoom(15);
    mapInstance.setCenter(positionObject(coords));
  };

  return (
    <Map
      id="LocationSelectot-LocationsMap-Map"
      google={google}
      zoom={mapConfigs.zoom}
      initialCenter={mapConfigs.center}
      center={mapConfigs.center}
      onReady={handleMapReady}
      disableDefaultUI={true}
      zoomControl={true}
      onZoom_changed={() => {
        setMapConfigs({...mapConfigs, zoom: mapInstance.getZoom()});
      }}
    >
      <SearchInput
        id="pac-input"
        className="controls"
        type="text"
        placeholder="Search Box"
        // style={{
        //   width: '300px',
        //   height: '30px',
        //   marginTop: '10px',
        //   marginLeft: '5px',
        //   // margin: '5px',
        //   padding: '0 5px',
        //   // top: '10px',
        // }}
      />
      {locations.length > 0 &&
        locations.map(location => {
          return (
            <Marker
              key={location.id}
              position={positionObject(location)}
              onClick={() => scrollToLocation(location.id)}
              // icon={{url: toppersMarker}}
            />
          );
        })}
      {coords && (
        <Marker
          position={positionObject(coords)}
          icon={{
            url: myLocationPin,
            scaledSize: new google.maps.Size(44, 44),
          }}
        />
      )}
      {coords && (
        <TakeMeToMyLocationWrapper onClick={bringMeToMyLocation}>
          <TakeMeToMyLocationIcon className="far fa-location-arrow" />
        </TakeMeToMyLocationWrapper>
      )}
    </Map>
  );
};

export default memo(
  connect(null, {setUserCoords})(
    geolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    })(
      GoogleApiWrapper(props => ({
        apiKey: 'AIzaSyALktQynT1ijyIoKCLD1IrNZa1kR7xQtxI',
      }))(LocationsMap)
    )
  )
);
